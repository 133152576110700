.customReactCampaignScheduleCalendar {
	.react-datepicker-wrapper {
		width: 100%;
	}

	.react-datepicker-popper {
		min-width: 360px;
		z-index: 9999 !important;
		position: absolute;
	}

	.react-datepicker__header {
		width: 100%;
		height: 68px;
		background: #f6f6f6;
	}

	.react-datepicker__current-month {
		padding-top: 7px;
		font-weight: 700;
		font-size: 15px;
		border-bottom: 1px solid #e7e5e5;
		padding-bottom: 10px;
	}

	.react-datepicker__day-names {
		background: #fff;
	}

	.react-datepicker__header--time {
		height: 49px;
		border-bottom: 1px solid #e7e5e5;
	}

	.react-datepicker__navigation {
		margin-top: 12px;
	}

	.react-datepicker__time {
		height: 210px;
	}

	.react-datepicker__time-list {
		height: 208px !important;
	}

	.react-datepicker__time-box {
		height: 210px !important;
	}

	.react-datepicker-time__header {
		padding-top: 8px;
	}

	.react-datepicker__day--selected {
		background-color: #3582db;
	}

	.react-datepicker__time-list-item--selected {
		background-color: #3582db !important;
	}

	.input {
		width: 100%;
		border: 1px solid #e2e8f0;
		border-radius: 4px;
		padding: 4px 12px !important;
		font-size: 14px;
		color: #202223;
		&:focus-visible {
			border: none;
			outline: 2px solid #3182ce;
		}
	}

	.react-datepicker__calendar-icon {
		padding: 2px 6px !important;
	}

	.react-datepicker__view-calendar-icon {
		position: relative;
	}

	.react-datepicker__view-calendar-icon svg {
		z-index: 5;
	}

	.react-datepicker__close-icon {
		margin-right: 25px;
	}

	.react-datepicker__day--outside-month {
		color: lightgray;
	}
}
