load-circle-loading {
	width: 23px;
	height: 23px;
}

.circle-bg-loading {
	fill: none;
	stroke: #4f4f4f;
	stroke-width: 10;
}

.circle-loading-1 {
	fill: none;
	stroke: #ffffff;
	stroke-width: 10;
	stroke-dasharray: 283;
	stroke-dashoffset: 283;
	transform: rotate(-90deg);
	transform-origin: 50% 50%;
	animation: fill 6s linear infinite;
}

.circle-loading-2 {
	fill: none;
	stroke: #ffffff;
	stroke-width: 10;
	stroke-dasharray: 283;
	stroke-dashoffset: 283;
	transform: rotate(-90deg);
	transform-origin: 50% 50%;
	animation: fill 18s linear infinite;
}

.circle-loading-3 {
	fill: none;
	stroke: #ffffff;
	stroke-width: 10;
	stroke-dasharray: 283;
	stroke-dashoffset: 283;
	transform: rotate(-90deg);
	transform-origin: 50% 50%;
	animation: fill 36s linear infinite;
}

@keyframes fill {
	to {
		stroke-dashoffset: 0;
	}
}

.checkmark {
	display: inline-block;
	width: 22px;
	height: 22px;
	-ms-transform: rotate(20deg);
	/* IE 9 */
	-webkit-transform: rotate(20deg);
	/* Chrome, Safari, Opera */
	transform: rotate(20deg);
}

.checkmark_stem {
	position: absolute;
	width: 3px;
	height: 19px;
	background-color: #f04d24;
	left: 20px;
	top: -4px;
}

.checkmark_kick {
	position: absolute;
	width: 10px;
	height: 3px;
	background-color: #f04d24;
	left: 12px;
	top: 12px;
}
