.customReactCampaignCalendar {
	.react-datepicker {
		border: 1px solid #e7e5e5;
	}
	.react-datepicker__header {
		width: 100%;
		height: 68px;
		background: #f6f6f6;
	}

	.react-datepicker__current-month {
		padding-top: 7px;
		font-weight: 500;
		font-size: 15px;
	}

	.react-datepicker__navigation {
		margin-top: 12px;
	}

	.react-datepicker__month-container {
		width: 100%;
		height: 326px;
	}

	.react-datepicker__day-names {
		display: flex;
		justify-content: space-around;
		border-top: 1px solid #e7e5e5;
		background-color: #fff;
		margin-top: 16px;
	}

	.react-datepicker__month {
		margin-top: 28px;
		display: flex;
		flex-direction: column;
		height: 226px;
		justify-content: space-around;
	}

	.react-datepicker__week {
		display: flex;
		justify-content: space-around;
		gap: 16px;
	}

	.date_picker_wrapper {
		width: 100%;
	}

	.react-datepicker__day {
		background: inherit;
	}

	.react-datepicker__day--selected {
		width: 30px;
		height: 28px;
		color: black;
		font-weight: 400;
		background-color: white;
		border: none;
	}

	.react-datepicker__day--keyboard-selected {
		background: inherit;
	}

	.react-datepicker__day--outside-month {
		color: lightgray;
	}
}
